.clientTableContainer table {
  font-family: "Roboto" !important;
  background-color: #ffffff !important;
  min-width: 500px !important;
}

.clientTableContainer table td,
.clientTableContainer table th {
  color: #484d56 !important;
  height: 20px !important;
  font-size: 14px !important;
  font-family: "Roboto" !important;
  padding: 6px 0px !important;
  letter-spacing: 0.5px !important;
}

.clientTableContainer table tr:first-child {
  background-color: #e0e0e0;
}
